import React from 'react';
//import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Nav = props => {

    return(
        
        <header>
  
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
           
                <Link to='/' >
                    <img src={ '/images/logos/logo.png' } alt="Aniosa" className="navbar-brand" />
                </Link>

                <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#main-nav">
                    <span className="menu-icon-bar"></span>
                    <span className="menu-icon-bar"></span>
                    <span className="menu-icon-bar"></span>
                </button>
                <div id="main-nav" className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        <li><a href="/" className="nav-item nav-link">HOME</a></li>
                        <li className="dropdown">
                            <a href="/" className="nav-item nav-link" data-toggle="dropdown">Apply Now</a>
                            <div className="dropdown-menu">
                                <a href="admission-requirements" className="dropdown-item">Admission Requirements</a>
                                <a href="how-to-apply" className="dropdown-item">How To Apply</a>
                                <a href="academic-programmes" className="dropdown-item">Academic Programmes</a>
                                <a href="Prospectus" className="dropdown-item">Prospectus</a>
                            </div>
                        </li>
                        <li className="dropdown">
                            <a href="/" className="nav-item nav-link" data-toggle="dropdown">Life@Aniosa</a>
                            <div className="dropdown-menu">
                                <a href="accomodation" className="dropdown-item">Accomodation</a>
                                <a href="sports" className="dropdown-item">Sports</a>
                                <a href="safety" className="dropdown-item">Safety</a>
                                <a href="students" className="dropdown-item">Students</a>
                            </div>
                        </li>
                        <li><a href="farmer-support" className="nav-item nav-link" >Farmer Support</a></li>
                        <li><a href="library" className="nav-item nav-link">Library</a></li>
                        <li><a href="contact-us" className="nav-item nav-link last-link-item">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
  
    </header>
    ); 

}

export default Nav;