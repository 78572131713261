import React from 'react'

const Governance = ( props ) => {

    return (
        <React.Fragment>
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-info-layer">
                            <h5 className="subtitle">Student Governance</h5>
                        
              
                            </div>
                        </div>
                    </div>
                   
     
                </div>
         
        </React.Fragment>
    );

}

export default Governance;