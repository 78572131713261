
import React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// layouts
import MainLayout from './Layouts/MainLayout';
import SecondLayout from './Layouts/SecondLayout';


//pages
import HomePage from './pages/HomePage/index';
//apply
import AdmissionRequirements from './pages/Apply/Requirements/index';
import HowToApply from './pages/Apply/HowToApply/index';
import AcademicProgrammes from './pages/Apply/AcademicProgrammes/index';
import Prospectus from './pages/Apply/Prospectus/index';
//students
import Accomodation from './pages/Campus/Accomodation/index';
import Governance from './pages/Campus/Governance/index';
import Sports from './pages/Campus/Sports/index';
import Safety from  './pages/Campus/Safety/index';

import FarmerSupport from './pages/FarmerSupport/index';
import Library from './pages/Library/index';
import ContactUs from './pages/Contact/index';

import './App.css';


function App() {


  return (
   <BrowserRouter>

      <Routes>

          <Route exact path="/"  element={<MainLayout />}>
            <Route index element={<HomePage />} />
          </Route>

          <Route  element={<SecondLayout />}>
            
            <Route path="/admission-requirements" element={<AdmissionRequirements />} />
            <Route path="/how-to-apply" element={<HowToApply />} />
            <Route path="/academic-programmes" element={<AcademicProgrammes />} />
            <Route path="/prospectus" element={<Prospectus />} />

            <Route path="/accomodation" element={<Accomodation />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/safety" element={<Safety />} />
            <Route path="/students" element={<Governance />} />

            <Route path="/farmer-support" element={<FarmerSupport />} />
            <Route path="/library" element={<Library />} />
            <Route path="/contact-us" element={<ContactUs />} />

          </Route>

      </Routes>

   </BrowserRouter>
  );
}

export default App;
