import React from 'react';
import Header  from '../components/Header/index';
import Footer  from '../components/Footer/index';
import { Outlet } from "react-router-dom";
import '../App.css';

const MainLayout = props => {
 

  return (
    <React.Fragment>
      <Header {...props} />

     
      <section >
          <Outlet />
      </section>

      <Footer />

    </React.Fragment>
  );
};
  
  export default MainLayout;