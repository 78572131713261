import React from 'react';
import Nav  from '../components/Nav/index';
import Footer  from '../components/Footer/index';
import { Outlet } from "react-router-dom";
import '../App.css';

const MainLayout = props => {
 

  return (
    <React.Fragment>
     
     <header>
        <Nav />

        <div className="pages-hero">
            <div className="container">
                <div className="pages-title">
                    <h5>Higher Education and Training (HET) institution</h5>
                    <h1>Apply</h1>
                    <p>AgriNurse Institute of Southern Africa</p>
                </div>
            </div>
        </div>

     </header>

     
      <section >
          <Outlet />
      </section>

      <Footer />

    </React.Fragment>
  );
};
  
  export default MainLayout;