import React from 'react'

const HowToApply = ( props ) => {

    return (
        <React.Fragment>
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-info-layer">
                            <h5 className="subtitle">How To Apply</h5>
                            <h2>Please select your Course.</h2>
                      
              
                            </div>
                        </div>
                    </div>
                   
     
                </div>
         
        </React.Fragment>
    );

}

export default HowToApply;