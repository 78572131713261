import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

import HeroSlider, { Slide, Overlay } from 'hero-slider';
import Wrapper from './Wrapper/index';

const HomeSlider = props => {


    return(
        <div className="home-slider">

            <HeroSlider
                height="85vh"
                accessability={{
                    shouldDisplayButtons: false,
                    // shouldSlideOnArrowKeypress: true
                  }}
                controller={{
                    initialSlide: 1,
                    slidingDuration: 400,
                    slidingDelay: 100,
                
                }}
                autoplay
                >
                

                <Slide
                  
                    background={{
                        backgroundAnimation: 'fade',
                        backgroundImageSrc: "/images/commons/slider-bg-1.jpg"
                    }}>
                    <Overlay>
                        <Wrapper />
                        <div className="container slider-caption">
                            <h5 className="subtitle">AgriNurse Institute of Southern Africa</h5>
                            <h1>  Why Study @Aniosa</h1>
                            <p>
                                Obtain training and development from our institute
                                of growing farmers and farm workers
                               </p>
                            <a className="btn btn-default btn-outline" href="demo/farmey/about-us.html" role="button">APPLY NOW</a>
                        </div>
                        <div className="social-media">
                            <ul>
                                <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="/"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </Overlay>
                </Slide>


                <Slide
                    background={{
                    backgroundImageSrc: "/images/commons/slider-bg-2.jpg"
                    }}>
                    <Overlay>
                        <Wrapper />
                        <div className="container slider-caption">
                            <h5 className="subtitle"> AgriNurse Institute of Southern Africa</h5>
                            <h1>Recognition</h1>
                            <p>Recognized by AgriSETA to provide Agricultural Education and Training</p>
                            <a className="btn btn-default btn-outline" href="demo/farmey/about-us.html" role="button">APPLY NOW</a>
                        </div>
                        <div className="social-media">
                            <ul>
                                <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="/"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </Overlay>
                </Slide>

            </HeroSlider>
        </div>
    
    ); 

}

export default HomeSlider;