import React from 'react'

const HomePage = ( props ) => {

    return (
        <React.Fragment>
          <div className="services-layer mb-5">
            <div className="container">
                <div className="section-title">
                    <h2>Services</h2>
                    <h3>Other Services</h3>
                    
                </div>
                <div className="col-row">
                    <div className="col-md-12 col-lg-10 mx-auto">
                        <div className="row">
                            <div className="col-md-6 col-lg-6" data-aos="fade-up">
                                <div className="service-box">
                                    <div className="media">
                                        <img src={ "/images/icons/tractor.png" } className="mr-3 sb-icon" alt="..." />
                                        <div className="media-body sb-caption">
                                            <h4>Technical Advice</h4>
                                            <p>Provide technical advice to local schools, farmers and community.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6" data-aos="fade-up">
                                <div className="service-box">
                                    <div className="media">
                                        <img src={ "/images/icons/tractor.png" } className="mr-3 sb-icon" alt="..." />
                                        <div className="media-body sb-caption">
                                            <h4>Research and Development</h4>
                                            <p>Participate in agriculture research and development.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6" data-aos="fade-up">
                                <div className="service-box">
                                    <div className="media">
                                        <img src={ "/images/icons/tractor.png" } className="mr-3 sb-icon" alt="..." />
                                        <div className="media-body sb-caption">
                                            <h4>Promote Partnerships</h4>
                                            <p>Encourage and promote partnerships with different stakeholders</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contact-bg mb-5">
            <div className="row">
                <div className="col-lg-6" data-aos="fade-right">
                
                    <div className="home-map">

                        <iframe
                            title='map'
                            src= { "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.369896484936!2d27.933882115591114!3d-26.021450583519552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9576234c26e109%3A0x4704a0242ff6d78a!2sNew%20Hampshire%20Cres%2C%20Cosmo%20City%2C%20Roodepoort%2C%202188!5e0!3m2!1sen!2sza!4v1673093647837!5m2!1sen!2sza" }
                            className="map-iframe"></iframe>
                    </div>
               
                </div>
                <div className="col-lg-6" data-aos="fade-left">
                    <div className="contact-info-right">
                        <div className="badges-box">
                            <div className="media">
                                <img src={ "/images/icons/email-white.png" } className="mr-3" alt="..." />
                                <div className="media-body">
                                    <h5 className="mt-0">academy@aniosa.co.za</h5>
                                    <p>Contact us if you have a question</p>
                                </div>
                            </div>
                            <div className="media white-bg">
                                <img src={ "/images/icons/phone-orange.png" } className="mr-3" alt="..." />
                                <div className="media-body">
                                    <h5 className="mt-0">+27 68 061 6843</h5>
                                    <p>Send us an email to keep in touch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    ) 
    
}

export default  HomePage;