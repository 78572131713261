import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

import Nav from '../Nav/index';
import HomeSlider from '../HomeSlider/index';


const Header = props => {

    return(
        
        <header>
  
        <Nav />
        <HomeSlider />
       
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-lg-6">
                    <div className="home-about">
                        <h2>About Us</h2>
                        <h3>The AgriNurse Institute of Southern Africa</h3>
                        <p>The AgriNurse Institute of Southern Africa is a training and development institute that is recognized by AgriSETA with accreditation number: AGRI/c prov/2036/22 and has been established to provide agricultural education within the South African higher education sector.</p>
                        <p> It is a Higher Education and Training (HET) institution.</p>
                        <div className="hb-box d-flex flex-row">
                            <div className="hb-inner">
                                <figure className="hb-icon">
                                    <img src={ "/images/icons/shield-orange-2.png" } alt="" />
                                </figure>
                                <h5>We are Passionate</h5>
                                <p>Center of excellence in training and applied research for development of the agricultural sector. </p>
                            </div>
                            <div className="hb-inner spacing-sm">
                                <figure className="hb-icon">
                                    <img src= { "/images/icons/wheat-1.png" }  alt="" />
                                </figure>
                                <h5>Industry Oriented</h5>
                                <p>Provision of structured agricultural training and conducting of applied research towards empowering of farmers and farm workers.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 spacing-md">
                    <figure className="home-about-img">
                        <img src= { "/images/commons/farmey-1.jpg" }  alt="" />
                        <div className="image-badget">
                            <figure className="ib-icon">
                                <img src= { "/images/icons/medal.png" }  alt="" />
                            </figure>
                            <h4>Our Values</h4>
                            <p>Cexcellence in training and applied research for development.</p>
                        </div>
                    </figure>
                </div>
            </div>
        </div>
    </header>
    ); 

}

export default Header;