import React from 'react'

const AdmissionRequirements = ( props ) => {

    return (
        <React.Fragment>
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-info-layer">
                            <h5 className="subtitle">Admission Requirements</h5>
                            <h2>Please select your Course.</h2>
                            <p>The AgriNurse Institute of Southern Africa is a training and development institute that is recognized by AgriSETA with accreditation number: AGRI/c prov/2036/22 and has been established to provide agricultural education within the South African higher education sector</p>
                            <p>It is a Higher Education and Training (HET) institution. </p>
                            <div className="more-details-layer">
                                <div className="media contact-layer">
                                    <img src= { "/images/icons/phone-orange.png" } className="mr-3" alt="..." />
                                    <div className="media-body">
                                        <h6>Contact us for Service</h6>
                                        <h3><a href="tel:068-061-6843">+27 68 061 6843/ +27 789 7710</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 spacing-md">
                        <figure className="about-img-layer">
                            <img src="images/commons/farmey-6.jpg" alt="" />
                            <div className="experience-layer">
                                <h4>10 Years Of Experience</h4>
                            </div>
                        </figure>
                    </div>
                </div>
                </div>
         
        </React.Fragment>
    );

}

export default AdmissionRequirements;