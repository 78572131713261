import React from 'react';
// import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';


const Footer = () => {
    return (
        <footer className="site-footer mt-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 footer-col left-col">
                        <figure className="footer-logo">
                            <img src={ "/images/logos/logo.png" } alt="" />
                        </figure>
                        <p>The AgriNurse Institute of Southern Africa is a training and development institute that is recognized by AgriSETA with accreditation number: AGRI/c prov/2036/22 and has been established to provide agricultural education within the South African higher education sector. It is a Higher Education and Training (HET) institution.</p>
                        <div className="footer-social">
                            <ul>
                                <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="/"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 footer-col spacing-m-center">
                        <h5>Apply Now</h5>
                        <ul className="quick-links left-layer">
                            <li><a href="/">Admission Requirements</a></li>
                            <li><a href="/">How To Apply</a></li>
                            <li><a href="/">Academic Programmes</a></li>
                            <li><a href="/">Prospectus</a></li>
                        </ul>
                        
                        <ul className="quick-links right-layer">
                        <h5 style={{ marginBottom: '0', marginTop: '30px' }}>Campus Life</h5>
                        <li><a href="/">Accomodation</a></li>
                            <li><a href="/">Sports</a></li>
                            <li><a href="/">Safety</a></li>
                            <li><a href="/">Student</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 footer-col">
                        <h5>Other Services</h5>
                        <ul className="quick-links left-layer">
                            <li><a href="/">Advisory</a></li>
                            <li><a href="/">Research & Development</a></li>
                            <li><a href="/">Partnership engagements</a></li>
                        </ul>
                    </div>
                </div>
                <hr className="footer" />
                <div className="bottom-footer">
                    <p>© 2023 ANIOSA - AgriNurse Institute of Southern Africa </p>
                </div>
            </div>
    </footer>
    );
}

export default Footer;